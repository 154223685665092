import {ResourceTypeUnion, Task, TaskPhoto, UserPhoto} from "@co-common-libs/resources";
import {
  actions,
  getCurrentUserURL,
  getShareToken,
  getTaskFileArray,
  getTaskPhotoArray,
} from "@co-frontend-libs/redux";
import {Button, Grid} from "@material-ui/core";
import {uploadFile} from "app-utils";
import {mobile} from "bowser";
import _ from "lodash";
import React, {useCallback, useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DragAndDropUploadOverlay} from "./drag-and-drop-upload-overlay";
import {appPhotoHelper, PDFInstance, PhotoInstance} from "./files";

interface PhotoAndPDFUploadProps {
  editDisabled: boolean;
  onPhotoDisplay: (photo: TaskPhoto | UserPhoto) => void;
  onRequestFileDelete: (url: string) => void;
  onRequestPhotoDelete: (url: string) => void;
  task: Task;
}
const photoInstanceStyle: React.CSSProperties = !mobile
  ? {margin: 7.5, marginLeft: 0, marginRight: 15}
  : {marginBottom: 15, marginTop: 7.5};

const gridContainerStyle: React.CSSProperties = {paddingBottom: 7.5};

export function PhotoAndPdfUpload(props: PhotoAndPDFUploadProps): React.JSX.Element {
  const {editDisabled, onPhotoDisplay, onRequestFileDelete, onRequestPhotoDelete, task} = props;

  const intl = useIntl();

  const shareToken = useSelector(getShareToken);
  const dispatch = useDispatch();
  const taskFileArray = useSelector(getTaskFileArray);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const currentUserURL = useSelector(getCurrentUserURL);

  const taskPhotoList = useMemo(() => {
    return _.sortBy(
      taskPhotoArray.filter((instance) => instance.task === task.url),
      (instance) => instance.taken + instance.id,
    );
  }, [task.url, taskPhotoArray]);

  const taskFileList = useMemo(() => {
    return taskFileArray.filter((instance) => instance.task === task.url);
  }, [task.url, taskFileArray]);

  const create = useCallback(
    (instance: ResourceTypeUnion): void => {
      dispatch(actions.create(instance));
    },
    [dispatch],
  );

  const addToOffline = useCallback(
    (instance: ResourceTypeUnion): void => {
      dispatch(actions.addToOffline(instance));
    },
    [dispatch],
  );

  const handleRejectedFile = useCallback(
    (file: File): void => {
      const message = intl.formatMessage(
        {defaultMessage: "Ikke en gyldig billedfil: {name}"},
        {name: file.name},
      );
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, intl],
  );

  const handleAcceptedFiles = useCallback(
    (files: File[]): void => {
      const now = new Date();
      const commonExtraData = {
        createdBy: currentUserURL || undefined,
        note: "",
        taken: now.toISOString(),
        task: task.url,
      };
      files.forEach((file) => {
        uploadFile(file, "taskPhoto", commonExtraData, "original", create, addToOffline);
      });
    },
    [addToOffline, create, currentUserURL, task.url],
  );

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper((window as any).Camera.PictureSourceType.CAMERA, handleAcceptedFiles);
  }, [handleAcceptedFiles]);

  const handleImageFiles = useCallback(
    (files: File[]): void => {
      const invalidFile = files.find((file) => !["image/jpeg", "image/png"].includes(file.type));
      if (invalidFile) {
        handleRejectedFile(invalidFile);
      } else {
        handleAcceptedFiles(files);
      }
    },
    [handleAcceptedFiles, handleRejectedFile],
  );

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      const selectedFiles = e.target.files;
      if (!selectedFiles) {
        return;
      }
      const files: File[] = [];
      for (let i = 0; i < selectedFiles.length; i += 1) {
        const file = selectedFiles[i];
        files.push(file);
      }
      handleImageFiles(files);
    },
    [handleImageFiles],
  );

  const handleAcceptedPdfFiles = useCallback(
    (files: File[]): void => {
      const commonExtraData = {
        createdBy: currentUserURL || undefined,
        note: "",
        task: task.url,
      };

      files.forEach((file) => {
        uploadFile(file, "taskFile", commonExtraData, "data", create, addToOffline);
      });
    },
    [addToOffline, create, currentUserURL, task.url],
  );

  const handlePDFFiles = useCallback(
    (files: File[]): void => {
      const invalidFile = files.find((file) => file.type !== "application/pdf");
      if (invalidFile) {
        handleRejectedFile(invalidFile);
      } else {
        handleAcceptedPdfFiles(files);
      }
    },
    [handleRejectedFile, handleAcceptedPdfFiles],
  );

  const handlePDFFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      e.preventDefault();
      e.stopPropagation();
      const selectedFiles = e.target.files;
      if (!selectedFiles) {
        return;
      }
      const max = selectedFiles.length;
      const files = [];
      for (let i = 0; i < max; i += 1) {
        const file = selectedFiles[i];
        files.push(file);
      }

      handlePDFFiles(files);
      // See: https://github.com/ngokevin/react-file-reader-input/issues/11#issuecomment-612959113
      // Resolves: https://github.com/customoffice-dk/customoffice/issues/1684
      const {target = {}} = e || {};
      (target as any).value = "";
    },
    [handlePDFFiles],
  );

  const handleDragDropFiles = useCallback(
    (files: File[]): void => {
      const invalidFile = files.find(
        (file) => !["application/pdf", "image/jpeg", "image/png"].includes(file.type),
      );

      if (invalidFile) {
        handleRejectedFile(invalidFile);
      } else {
        const imageFiles: File[] = [];
        const pdfFiles: File[] = [];

        files.forEach((file) => {
          if (["image/jpeg", "image/png"].includes(file.type)) {
            imageFiles.push(file);
          } else {
            pdfFiles.push(file);
          }
        });

        handleImageFiles(imageFiles);
        handleAcceptedPdfFiles(pdfFiles);
      }
    },
    [handleImageFiles, handleRejectedFile, handleAcceptedPdfFiles],
  );

  const photoElementList = taskPhotoList.map((taskPhoto) => {
    return (
      <div key={taskPhoto.url} style={photoInstanceStyle}>
        <PhotoInstance
          editDisabled={editDisabled}
          key={taskPhoto.url}
          onDeleteButton={onRequestPhotoDelete}
          onDisplay={onPhotoDisplay}
          photo={taskPhoto}
          shareToken={shareToken}
        />
      </div>
    );
  });
  const fileElementList = taskFileList.map((taskFile) => {
    return (
      <div key={taskFile.url} style={photoInstanceStyle}>
        <PDFInstance
          editDisabled={editDisabled}
          file={taskFile}
          key={taskFile.url}
          onDeleteButton={onRequestFileDelete}
          shareToken={shareToken}
        />
      </div>
    );
  });
  const photosButton = (
    <>
      <input
        accept="image/jpeg,image/png"
        disabled={editDisabled}
        id="photos-input"
        multiple
        onChange={handleFileInputChange}
        style={{display: "none"}}
        type="file"
      />
      <label htmlFor="photos-input">
        <Button color="secondary" component="span" disabled={editDisabled} variant="contained">
          <FormattedMessage defaultMessage="Vælg billede" />
        </Button>
      </label>
    </>
  );

  const pdfButton = (
    <>
      <input
        accept="application/pdf"
        disabled={editDisabled}
        id="pdf-input"
        multiple
        onChange={handlePDFFileInputChange}
        style={{display: "none"}}
        type="file"
      />
      <label htmlFor="pdf-input">
        <Button color="secondary" component="span" disabled={editDisabled} variant="contained">
          <FormattedMessage defaultMessage="Tilføj PDF" />
        </Button>
      </label>
    </>
  );

  const cameraButton = window.cordova ? (
    <Button
      color="secondary"
      disabled={editDisabled}
      onClick={handleAppCameraButton}
      variant="contained"
    >
      <FormattedMessage defaultMessage="Tag billede" />
    </Button>
  ) : null;

  return (
    <DragAndDropUploadOverlay onFiles={handleDragDropFiles}>
      {mobile ? (
        <>
          <Grid container spacing={1} style={gridContainerStyle}>
            {cameraButton ? (
              <>
                <Grid item xs={4}>
                  {cameraButton}
                </Grid>
                <Grid item xs={4}>
                  {photosButton}
                </Grid>
                <Grid item xs={4}>
                  {pdfButton}
                </Grid>
              </>
            ) : (
              <>
                <Grid item>{photosButton}</Grid>
                <Grid item>{pdfButton}</Grid>
              </>
            )}
          </Grid>
          {photoElementList}
          {fileElementList}
        </>
      ) : (
        <>
          <Grid container spacing={2} style={gridContainerStyle}>
            <Grid item>{photosButton}</Grid>
            <Grid item>{pdfButton}</Grid>
          </Grid>
          <Grid container>
            {photoElementList}
            {fileElementList}
          </Grid>
        </>
      )}
    </DragAndDropUploadOverlay>
  );
}
