import {Task, TaskFile, TaskPhoto, urlToId, UserPhoto} from "@co-common-libs/resources";
import {Check, makeQuery} from "@co-frontend-libs/db-resources";
import {
  actions,
  getCustomerSettings,
  getOrderLookup,
  getPathName,
  getQueriesSyncedState,
  getShareToken,
  getTaskArray,
  getTaskFileArray,
  getTaskPhotoArray,
} from "@co-frontend-libs/redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import {PDFInstance, PhotoInstance} from "app-components";
import {mobile} from "bowser";
import {theme} from "frontend-global-config";
import _ from "lodash";
import ExpandMoreIcon from "mdi-react/ExpandMoreIcon";
import React, {ReactNode, useEffect, useMemo} from "react";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface FileWithTaskData {
  fileInstance: TaskFile;
  taskStartTimestamp: string | null;
  taskURL: string;
}

const photoInstanceStyle: React.CSSProperties = mobile
  ? {marginBottom: 7.5, marginTop: 7.5, width: "100%"}
  : {marginRight: 15, width: 292};

const OtherFilesContent = React.memo(function OtherFilesContent({
  filesWithTaskData,
}: {
  filesWithTaskData: readonly FileWithTaskData[];
}): React.JSX.Element {
  const sortedFiles = useMemo(
    () =>
      _.orderBy(
        filesWithTaskData,
        [(file) => file.taskStartTimestamp, (file) => file.fileInstance.created],
        ["desc", "desc"],
      ),
    [filesWithTaskData],
  );
  const shareToken = useSelector(getShareToken);

  return (
    <div>
      {sortedFiles.map((file) => (
        <PDFInstance
          editDisabled
          file={file.fileInstance}
          key={file.fileInstance.url}
          shareToken={shareToken}
        />
      ))}
    </div>
  );
});

interface PhotoWithTaskData {
  photoInstance: TaskPhoto;
  taskStartTimestamp: string | null;
  taskURL: string;
}

const OtherPhotosContent = React.memo(function OtherPhotosContent({
  onPhotoDisplay,
  photosWithTaskData,
}: {
  onPhotoDisplay: (photo: TaskPhoto | UserPhoto) => void;
  photosWithTaskData: readonly PhotoWithTaskData[];
}): React.JSX.Element[] {
  const sortedPhotos = useMemo(
    () =>
      _.orderBy(
        photosWithTaskData,
        [(photo) => photo.taskStartTimestamp, (photo) => photo.photoInstance.taken],
        "desc",
      ),
    [photosWithTaskData],
  );
  const shareToken = useSelector(getShareToken);

  return sortedPhotos.map((photo) => (
    <Grid item key={photo.photoInstance.url} style={photoInstanceStyle}>
      <PhotoInstance
        editDisabled
        key={photo.photoInstance.url}
        onDisplay={onPhotoDisplay}
        photo={photo.photoInstance}
        shareToken={shareToken}
      />
    </Grid>
  ));
});

const TEMPORARY_QUERIES_KEY = "otherPhotosAndFiles";

interface OtherPhotosAndFilesProps {
  currentTask: Task;
  onPhotoDisplay: (photo: TaskPhoto | UserPhoto) => void;
}

const accordionSummaryStyle: React.CSSProperties = {
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
};

const accordionDetailsStyle: React.CSSProperties = mobile
  ? {
      backgroundColor: theme.palette.grey[300],
      paddingBottom: 15,
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 7.5,
    }
  : {
      backgroundColor: theme.palette.grey[300],
      padding: 15,
    };

export function OtherPhotosAndFiles({
  currentTask,
  onPhotoDisplay,
}: OtherPhotosAndFilesProps): React.JSX.Element {
  let message: React.JSX.Element | undefined;
  const dispatch = useDispatch();
  const pathName = useSelector(getPathName);
  const orderLookup = useSelector(getOrderLookup);
  const syncedState = useSelector(getQueriesSyncedState);
  const taskArray = useSelector(getTaskArray);
  const taskPhotoArray = useSelector(getTaskPhotoArray);
  const taskFileArray = useSelector(getTaskFileArray);
  const customerSettings = useSelector(getCustomerSettings);
  const currentOrder = useMemo(() => {
    return currentTask.order ? orderLookup(currentTask.order) : null;
  }, [orderLookup, currentTask]);

  const queries = useMemo(() => {
    let taskFilter: {[key: string]: string} | undefined;
    let taskCheck: Check | undefined;
    const customer = currentOrder?.customer;
    if (currentTask.referenceNumber) {
      taskFilter = {referenceNumber: currentTask.referenceNumber};
      taskCheck = {
        memberName: "referenceNumber",
        type: "memberEq",
        value: currentTask.referenceNumber,
      };
    } else if (currentTask.project) {
      taskFilter = {projectId: urlToId(currentTask.project)};
      taskCheck = {
        memberName: "project",
        type: "memberEq",
        value: currentTask.project,
      };
    } else if (currentTask.relatedWorkplace) {
      taskFilter = {locationID: urlToId(currentTask.relatedWorkplace)};
      taskCheck = {
        memberName: "relatedWorkplace",
        type: "memberEq",
        value: currentTask.relatedWorkplace,
      };
    }
    if (taskFilter && taskCheck) {
      const taskQuery = makeQuery({
        check: taskCheck,
        filter: taskFilter,

        independentFetch: true,
        // related: ["order", "taskPhoto"],
        resourceName: "task",
      });
      const orderQuery = makeQuery({
        check: {
          check: taskCheck,
          fromResource: "task",
          memberName: "order",
          type: "targetOfForeignKey",
        },
        independentFetch: false,
        resourceName: "order",
      });
      const taskPhotoQuery = makeQuery({
        check: {
          check: taskCheck,
          memberName: "task",
          targetType: "task",
          type: "hasForeignKey",
        },
        independentFetch: false,
        resourceName: "taskPhoto",
      });
      return [taskQuery, orderQuery, taskPhotoQuery];
    } else if (customer) {
      const orderCheck: Check = {
        memberName: "customer",
        type: "memberEq",
        value: customer,
      };
      const orderTaskCheck: Check = {
        check: orderCheck,
        memberName: "order",
        targetType: "order",
        type: "hasForeignKey",
      };
      const orderQuery = makeQuery({
        check: orderCheck,
        filter: {
          customerID: urlToId(customer),
        },
        independentFetch: true,
        resourceName: "order",
      });
      const taskQuery = makeQuery({
        check: orderTaskCheck,
        independentFetch: false,
        resourceName: "task",
      });
      const taskPhotoQuery = makeQuery({
        check: {
          check: orderTaskCheck,
          memberName: "task",
          targetType: "task",
          type: "hasForeignKey",
        },
        independentFetch: false,
        resourceName: "taskPhoto",
      });
      const taskFileQuery = makeQuery({
        check: {
          check: orderTaskCheck,
          memberName: "task",
          targetType: "task",
          type: "hasForeignKey",
        },
        independentFetch: false,
        resourceName: "taskFile",
      });
      return [orderQuery, taskQuery, taskPhotoQuery, taskFileQuery];
    } else {
      return null;
    }
  }, [
    currentOrder?.customer,
    currentTask.project,
    currentTask.referenceNumber,
    currentTask.relatedWorkplace,
  ]);

  useEffect(() => {
    if (queries) {
      dispatch(actions.temporaryQueriesRequestedForPath(queries, pathName, TEMPORARY_QUERIES_KEY));
    }
  }, [dispatch, pathName, queries]);

  let onlineWaiting = false;
  let onlineError = false;
  if (queries) {
    const queriesSyncedState = queries.map((q) => syncedState[q.keyString]);
    if (
      queriesSyncedState.some(
        (querySyncedState) =>
          !querySyncedState ||
          querySyncedState.queryState.currentlyFullFetching ||
          !querySyncedState.queryState.takenIntoAccountForChangesComputedAtTimestamp,
      )
    ) {
      onlineWaiting = true;
    } else {
      if (
        queriesSyncedState.some(
          (querySyncedState) => querySyncedState && querySyncedState.queryState.lastError,
        )
      ) {
        onlineError = true;
      }
    }
  }

  if (onlineWaiting) {
    message = <FormattedMessage defaultMessage="Henter filer fra andre opgaver" />;
  } else if (onlineError) {
    message = <FormattedMessage defaultMessage="Der opstod en fejl" />;
  }
  let messageContent: ReactNode;
  let contentLength = 0;
  if (message) {
    messageContent = (
      <div style={{padding: 8, textAlign: "center"}}>
        <div>{message}</div>
        {onlineWaiting ? <CircularProgress /> : null}
      </div>
    );
  }

  const filteredTasks = new Map<string, Task>();
  const photosWithTaskData: PhotoWithTaskData[] = [];
  const filesWithTaskData: FileWithTaskData[] = [];
  taskArray.forEach((task) => {
    if (!task.order || task.url === currentTask.url) {
      return;
    }
    if (currentTask.referenceNumber) {
      if (currentTask.referenceNumber === task.referenceNumber) {
        filteredTasks.set(task.url, task);
      }
      return;
    }
    if (currentTask.project) {
      if (currentTask.project === task.project) {
        filteredTasks.set(task.url, task);
      }
      return;
    }
    if (currentTask.relatedWorkplace) {
      if (currentTask.relatedWorkplace === task.relatedWorkplace) {
        filteredTasks.set(task.url, task);
      }
      return;
    }
    const order = task.order ? orderLookup(task.order) : null;
    if (order && currentOrder && currentOrder.customer === order.customer) {
      filteredTasks.set(task.url, task);
    }
  });

  if (customerSettings.taskShowRelatedPhotos) {
    taskPhotoArray.forEach((taskPhoto) => {
      const task = filteredTasks.get(taskPhoto.task);
      if (task) {
        photosWithTaskData.push({
          photoInstance: taskPhoto,
          taskStartTimestamp: task.workFromTimestamp || task.date,
          taskURL: task.url,
        });
      }
    });
  }
  if (customerSettings.taskShowRelatedFiles) {
    taskFileArray.forEach((taskFile) => {
      const task = filteredTasks.get(taskFile.task);
      if (task) {
        filesWithTaskData.push({
          fileInstance: taskFile,
          taskStartTimestamp: task.workFromTimestamp || task.date,
          taskURL: task.url,
        });
      }
    });
  }
  contentLength = photosWithTaskData.length + filesWithTaskData.length;
  const photos = (
    <>
      {customerSettings.taskShowRelatedPhotos ? (
        !photosWithTaskData.length ? (
          <FormattedMessage defaultMessage="Der er ingen fotos på andre opgaver" tagName="h2" />
        ) : (
          <OtherPhotosContent
            onPhotoDisplay={onPhotoDisplay}
            photosWithTaskData={photosWithTaskData}
          />
        )
      ) : null}
      {customerSettings.taskShowRelatedFiles ? (
        !filesWithTaskData.length ? (
          <FormattedMessage defaultMessage="Der er ingen PDF filer på andre opgaver" tagName="h2" />
        ) : (
          <OtherFilesContent filesWithTaskData={filesWithTaskData} />
        )
      ) : null}
    </>
  );

  return (
    <Accordion square={false}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color={theme.palette.common.white} />}
        style={accordionSummaryStyle}
      >
        <FormattedMessage
          defaultMessage={"Vis flere billeder ({count})"}
          values={{count: contentLength}}
        />
      </AccordionSummary>
      <AccordionDetails style={accordionDetailsStyle}>
        {mobile ? (
          <Grid container justifyContent="center">
            {message ? messageContent : photos}
          </Grid>
        ) : (
          <Grid container justifyContent="flex-start" spacing={2}>
            {message ? messageContent : photos}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
}
